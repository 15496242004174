import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import ArrowRight from 'icons/ArrowRight';
import Linkedin from 'icons/Linkedin';
import Instagram from 'icons/Instagram';
import Facebook from 'icons/Facebook';
import { useRouter } from 'next/router';

const Footer: React.FC = () => {
  const router = useRouter();
  const isAcademyPath = router.asPath.includes('/academy');
  return (
    <>
      <footer className="w-full mt-auto bg-black selection:bg-white">
        <div className="container">
          <div className="mx-auto max-w-[1400px] pt-8 pb-8 sm:pt-14 sm:pb-14 md:pb-36">
            <div className="grid max-w-md grid-cols-1 gap-8 mx-auto text-center sm:gap-12 md:max-w-none md:grid-cols-4 md:text-left ">
              <div className="order-last col-span-3 md:order-first md:col-span-1 ">
                <Link href="/">
                  <a>
                    {isAcademyPath ? (
                      <Image
                        className="mx-auto md:mx-0"
                        alt="Showroom logotype"
                        src="/assets/Showroom_Logotype_White_Academy.svg"
                        width="150"
                        height="35"
                      />
                    ) : (
                      <Image
                        className="mx-auto md:mx-0"
                        alt="Showroom logotype"
                        src="/assets/Showroom_Logotype_White.svg"
                        width="150"
                        height="35"
                      />
                    )}
                  </a>
                </Link>
                <p className="mt-2 select-text text-footer font-normal text-white opacity-50 selection:bg-white">
                  Vi revolutionerar shopping genom att koppla samman influencers, varumärken och konsumenter.
                </p>
                <p className="mt-2 text-xs font-normal text-white opacity-25 select-text text-footer selection:bg-white">
                  Morning Venture One AB<br />
                  Org.nr: 559483-1694
                </p>
              </div>
              <div className="col-span-3">
                <div className="grid grid-cols-1 gap-8 sm:gap-12 md:grid-cols-3">
                  <div className="col-span-1">
                    <h4 className="heading-h4 whitespace-nowrap text-white">Kontakt</h4>
                    <ul className="mt-[9px] space-y-2 text-footer font-normal text-white opacity-50">
                      <li>
                        <Link href="/kontakt">
                          <a>Kontakta oss</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="https://www.mynewsdesk.com/se/showroom">
                          <a>Nyheter & press</a>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-span-1">
                    <h4 className="heading-h4 whitespace-nowrap text-white">Policy</h4>
                    <ul className="mt-[9px]  space-y-2 text-footer font-normal text-white">
                      <li className="opacity-50">
                        <Link href="/privacy-policy">
                          <a>Cookies</a>
                        </Link>
                      </li>
                      <li className="opacity-50">
                        <Link href="/privacy-policy">
                          <a>Personuppgiftpolicy</a>
                        </Link>
                      </li>
                      <li className="opacity-50">
                        <Link href="/anvandarvillkor">
                          <a>Användarvillkor</a>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-span-1">
                    <h4 className="heading-h4 whitespace-nowrap text-white">Social</h4>
                    <ul className="justify-center md:justify-start mt-[9px] text-footer font-normal text-white opacity-50 flex flex-row items-center space-x-6">
                      <li>
                        <a
                          className="p-2"
                          href="https://www.instagram.com/showroom.sverige/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Instagram">
                          <Instagram />
                        </a>
                        </li>
                      <li>
                        <a
                          className="p-2"
                          href="https://www.facebook.com/Showroom.Sverige"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Facebook">
                          <Facebook />
                        </a>
                        </li>
                      <li>
                        <a
                          className="p-2"
                          href="https://www.linkedin.com/company/showroom-sverige/mycompany/?viewAsMember=true"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Linkedin">
                          <Linkedin />
                        </a>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
